// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bD_kC";
export var caseStudyBackground__lineColor = "bD_kz";
export var caseStudyHead__imageWrapper = "bD_kv";
export var caseStudyProjectsSection = "bD_kD";
export var caseStudyQuote__bgLight = "bD_kK";
export var caseStudyQuote__bgRing = "bD_kx";
export var caseStudyQuote__bottomVideo = "bD_kR";
export var caseStudyVideo = "bD_kM";
export var caseStudyVideo__ring = "bD_kN";
export var caseStudy__bgDark = "bD_ks";
export var caseStudy__bgLight = "bD_kr";